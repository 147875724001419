<template>
  <div class="login-box-right">
    <a-form :form="form" class="login-form" @submit="handleSubmit">
      <a-form-item>
        <a-input
          v-decorator="[
            'account',
            { rules: [{ required: true, message: '请输入用户名!' }] },
          ]"
          placeholder="用户名"
          :allowClear="true"
        >
          <a-icon
            slot="prefix"
            type="user"
            style="color: rgba(0, 0, 0, 0.25)"
          />
        </a-input>
      </a-form-item>
      <a-form-item>
        <a-input-password
          v-decorator="[
            'password',
            { rules: [{ required: true, message: '请输入密码!' }] },
          ]"
          type="password"
          placeholder="密码"
          :allowClear="true"
        >
          <a-icon
            slot="prefix"
            type="lock"
            style="color: rgba(0, 0, 0, 0.25)"
          />
        </a-input-password>
      </a-form-item>
      <a-form-item>
        <a-input-password
          v-decorator="[
            'password1',
            { rules: [{ required: true, message: '请输入确认密码!' }] },
          ]"
          type="password"
          placeholder="确认密码"
          :allowClear="true"
        >
          <a-icon
            slot="prefix"
            type="lock"
            style="color: rgba(0, 0, 0, 0.25)"
          />
        </a-input-password>
      </a-form-item>
      <a-form-item>
        <a-button type="primary" html-type="submit" class="login-form-button">
          点击注册
        </a-button>
      </a-form-item>
    </a-form>
  </div>
</template>

<script>
export default {
  name: "LoginReg",
  components: {},
  data() {
    return {
      logging: false,
      error: "",
      form: this.$form.createForm(this),
    };
  },
  computed: {},
  mounted() {},
  methods: {
    handleSubmit(e) {
      e.preventDefault();
      this.form.validateFields((err) => {
        if (!err) {
          this.logging = true;
          const account = this.form.getFieldValue("account");
          const password = this.form.getFieldValue("password");
          const password1 = this.form.getFieldValue("password1");
          if (password != password1) {
            this.$message.error("密码与确认密码不符");
            return false;
          }

          let params = {
            account,
            password,
          };
          console.log(params);
          this.$api
            .post(`v1/user/register`, params)
            .then((resp) => {
              if (resp.code == 200) {
                // window.localStorage.setItem("token", resp.data.token);
                this.$router.push("/");
              } else {
                this.$message.error(resp.message);
              }
              this.logging = false;
            })
            .catch((e) => {
              this.logging = false;
              throw e;
            });
        }
      });
    },
  },
};
</script>

<style lang="less" scoped>
.login-box-right {
      width: 100%;
      height: 680px;
      position: relative;

      .login-form {
        margin: 10px auto;
        max-width: 300px;
        .login-form-button {
          width: 100%;
        }
      }
    }

</style>
